import { useEffect } from "react";
import { useTheme } from "@mui/material";

export default function Favicon() {
  const theme = useTheme();

  useEffect(() => {
    const svg = `<svg width="23" height="23" xmlns="http://www.w3.org/2000/svg">
      
      <path fill="${theme.palette.primary.main}"
      d="M22.9931733,0 L22.9931733,23 L0,23 L0,0 L22.9931733,0 Z M11.4214953,5.198 L3.03509887,11.7687593 L3.64178137,12.5474805 L4.68097268,11.7331899 C4.60127936,12.0527986 4.5589696,12.3872496 4.5589696,12.7316054 C4.5589696,14.9983999 6.3923395,16.836 8.65391584,16.836 C9.87537718,16.836 10.9719306,16.2999714 11.7221991,15.449802 L11.7221991,15.449802 L11.7221956,16.836 L12.7488621,16.836 L12.7488586,15.449802 C13.4991271,16.2999714 14.5956805,16.836 15.8171418,16.836 L15.8171418,16.836 L15.8848593,16.8354501 C18.1152023,16.7992051 19.9120881,14.975732 19.9120881,12.7316054 C19.9120881,10.4648108 18.0787182,8.62721076 15.8171418,8.62721076 C14.5956805,8.62721076 13.4991272,9.16323928 12.7488587,10.0134088 L12.7488587,10.0134088 L12.7488621,5.76094777 L11.8600687,5.7609434 L11.4214953,5.198 Z M8.65391584,9.65330939 C10.3054779,9.65330939 11.6525597,10.9599526 11.7222901,12.5981235 L11.7222901,12.5981235 L11.7222901,12.8650873 C11.6525597,14.5032581 10.3054779,15.8099013 8.65391584,15.8099013 C6.95773357,15.8099013 5.58270615,14.4317013 5.58270615,12.7316054 C5.58270615,11.0315095 6.95773357,9.65330939 8.65391584,9.65330939 Z M15.8679299,9.65372183 C17.5406872,9.68090557 18.8883515,11.0485104 18.8883515,12.7316054 C18.8883515,14.4317013 17.5133241,15.8099013 15.8171418,15.8099013 C14.1655798,15.8099013 12.818498,14.5032581 12.7487676,12.8650873 L12.7487676,12.8650873 L12.7487676,12.5981235 C12.818498,10.9599526 14.1655798,9.65330939 15.8171418,9.65330939 L15.8171418,9.65330939 Z M11.722199,6.2164584 L11.722199,10.0134088 C10.9719305,9.16323928 9.87537714,8.62721076 8.65391584,8.62721076 L8.65391584,8.62721076 L8.6452125,8.627277 L11.722199,6.2164584 Z" 
      fill-rule="nonzero"
      />
    </svg>`;

    document.getElementById("favicon-svg")?.setAttribute(
      "href",
      `data:image/svg+xml;utf8,${encodeURIComponent(svg)
        .replace(/\n/g, "")
        .replace(/\s{2,}/g, "")}`
    );
  }, [theme.palette.mode, theme.palette.primary.main]);

  return null;
}
